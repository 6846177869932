import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  History,
  Loader, NoteDrawer,
  SimpleTabs,
} from 'components';
import { BillingDetails, FactoredTransactions, CreateFactoredLoadTable } from 'fragments';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, loadActions } from 'store';
import {
  FindErrorItem,
  FindLoad,
  FindSuccess,
  isFactored, isAdmin,
} from 'utils';
import { Transactions } from '../../fragments/loads/transactions';
import { PaymentVerificationDetails } from '../../fragments/paymentVerification';

const ACTION_TYPE = 'GET_LOAD_BY_ID';
const CANCEL_ACTION_TYPE = 'CANCEL_LOAD';

export const FactoredLoadDetails = () => {
  const { loadById } = useSelector((state) => ({
    loadById: state.loads.loadById,
  }));
  const params = useParams();
  const dispatch = useDispatch();
  const loader = FindLoad(ACTION_TYPE);
  const successCancel = FindSuccess(CANCEL_ACTION_TYPE);
  const backError = FindErrorItem(CANCEL_ACTION_TYPE);
  const [verification, setVerification] = useState(false);
  const [revision, setRevision] = useState(false);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState('Details');

  useEffect(() => {
    if (backError) {
      dispatch(httpRequestsOnErrorsActions.removeError(CANCEL_ACTION_TYPE));
      setOpen(false);
    }
  }, [backError]);

  useEffect(() => {
    if (loadById?.id) {
      setRevision(!!loadById?.isRevision);
      setVerification(!!loadById?.isVerification);
    }
  }, [loadById]);


  useEffect(() => {
    if (successCancel?.length) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(CANCEL_ACTION_TYPE));
      setOpen(false);
    }
  }, [successCancel]);

  const handleOpenCloseDel = () => {
    setOpen(!open);
  };

  const handleRemoveDoc = () => {
    dispatch(loadActions.cancelLoad(params?.id));
  };

  const tabsLabels = [
    {
      label: 'Details',
    },
    {
      label: 'Transactions',
    },
    isAdmin &&
    {
      label: 'Billing Details',
    },
    isAdmin &&
    {
      label: 'Payable Details',
    },
    isAdmin &&
    {
      label: 'History',
    },
    // isAdmin &&
    // {
    //   label: 'Notes',
    // },
  ];

  const tabsContent = [
    {
      tabComponent: <CreateFactoredLoadTable />,
      tab: 'Details',
    },
    isFactored &&
    {
      tabComponent: <FactoredTransactions load={loadById} />,
      tab: 'Transactions',
    },
    isAdmin &&
    {
      tabComponent: <Transactions load={loadById} />,
      tab: 'Transactions',
    },
    isAdmin &&
    {
      tabComponent: <BillingDetails loadById={loadById} />,
      tab: 'Billing Details',
    },
    isAdmin &&
    {
      tabComponent: <PaymentVerificationDetails loadById={loadById} />,
      tab: 'Payable Details',
    },
    isAdmin &&
    {
      tabComponent: <History onModel={'load'} resourceId={params.id} />,
      tab: 'History',
    },
  ];

  if (loader?.length) {
    return <Loader />;
  }

  return (
    <div>
      <>
        {loadById?.id ?
          <SimpleTabs
            verification={verification || revision}
            head={<NoteDrawer title={'Load'} loadId={loadById?.id} />}
            noBread={true}
            border={'blue'}
            height={'full'}
            tabsLabels={tabsLabels}
            tabsContent={tabsContent}
            defaultTab={tab}
            getTab={(tab) => setTab(tab)}
          />

          :
          <CreateFactoredLoadTable />

        }


        {/*<SimpleTabs*/}
        {/*  verification={verification || revision}*/}
        {/*  head={*/}
        {/*    <div className="space-between" style={{ gap: 15 }}>*/}
        {/*      <div className={classes.loadIdWrapper}>*/}
        {/*        <img src={Images.loadFillBlue} alt={'icon'} />*/}
        {/*        <p>{loadById?.displayId} </p>*/}
        {/*      </div>*/}
        {/*        <NoteDrawer title={'Load'} />*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*  noBread={true}*/}
        {/*  border={'blue'}*/}
        {/*  height={'full'}*/}
        {/*  tabsLabels={tabsLabels}*/}
        {/*  tabsContent={tabsContent}*/}
        {/*  defaultTab={'Details'}*/}
        {/*/>*/}


        {/*{*/}
        {/*  loadById?.bill?.totalPaid === 0 && loadById?.payable?.totalPaid === 0 &&*/}
        {/*  loadById?.status !== COMPLETED &&*/}
        {/*  loadById?.status !== CANCELLED &&*/}
        {/*  loadById?.status !== DENIED &&*/}
        {/*  loadById?.status !== REJECTED &&*/}
        {/*  <div className={classes.cancelLoadWrapper}>*/}
        {/*    <button onClick={() => handleOpenCloseDel()} className={classes.cancelLoad}>*/}
        {/*      Cancel Load*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*}*/}

        {/*<SimpleModal*/}
        {/*  handleOpenClose={() => handleOpenCloseDel()}*/}
        {/*  openDefault={open}*/}
        {/*  content={*/}
        {/*    <DeleteElement*/}
        {/*      cancelButton={'Cancel'}*/}
        {/*      closeButton={'Go Back'}*/}
        {/*      modalText={'Are you sure you want to cancel this load?'}*/}
        {/*      loading={!!cancelLoader?.length}*/}
        {/*      text={'Cancel Load?'}*/}
        {/*      handleClose={() => handleOpenCloseDel()}*/}
        {/*      handleDel={handleRemoveDoc}*/}
        {/*    />*/}
        {/*  }*/}
        {/*/>*/}
      </>
    </div>
  );
};
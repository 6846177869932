import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Checkbox, TableCell } from '@material-ui/core';
import {
  AddModalButton,
  CloseButton,
  CorrectionsModal, CsvFile, DateFilters,
  FullTable, PriceConvertor,
  SimpleModal, UserInputsDropdown,
} from 'components';
import { PayableCreatePayment } from 'fragments';
import {
  RolePermission,
  PermissionsList,
  Colors,
  useGlobalStyles,
  SavePage,
  usePrimaryModal,
  FindSuccessItem,
} from 'utils';
import { payablesStyles } from './styles';
import { carrierActions, httpRequestsOnSuccessActions, loadActions, LoadServices } from 'store';
import { payablesBody, payablesHead, ACTION_TYPE, PAYABLE, payableCsv } from './constants';
import { TotalItems } from '../receivables/core/totalItems';
import { LoadDetailSwitcher, RENDER_LOADS } from '../../loads';

export const Payables = ({}) => {
  const { loadsList, payableInfo, carriersList } = useSelector((state) => ({
    loadsList: state.loads.loadsList,
    payableInfo: state.loads.payableInfo,
    carriersList: state.carriers.carriersList,
  }));
  const classes = payablesStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;
  const [selectedLoads, setSelectedLoads] = useState([]);
  const [correctionLoad, setCorrectionLoad] = useState(false);
  const [correction, setCorrection] = useState('');
  const [data, setData] = useState('');
  const [open, setOpen] = useState('');
  const { openPrimary } = usePrimaryModal();
  const renderLoads = FindSuccessItem(RENDER_LOADS);
  const payableParams = {
    ...payableCsv,
    carrier: info?.carrier,
  };

  useEffect(() => {
    dispatch(carrierActions.getCarriers({ status: 'ACTIVE' }));
  }, []);

  const renderParams = () => {
    const filteredInfo = {
      ...info,
    };
    filteredInfo.skip = info?.skip ? info?.skip : 0;
    filteredInfo.limit = 100;
    delete filteredInfo.page;
    delete filteredInfo.activeTab;
    return filteredInfo;
  };

  useEffect(() => {
    handleGet('loading');
    const interval = setInterval(() => {
      handleGet('noLoad');
    }, 30000);
    return () => clearInterval(interval);
  }, [info]);

  useEffect(() => {
    if (renderLoads) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(RENDER_LOADS))
      handleGet('noLoad');
    }
  }, [renderLoads]);

  const handleGet = (loading) => {
    const loader = loading === 'loading' ? 'loading' : 'noLoad';
    const filteredInfo = renderParams();
    const payableInfo = renderParams();
    delete payableInfo.skip;
    delete payableInfo.limit;
    delete payableInfo.department;

    dispatch(loadActions.getPayableInfo(payableInfo));
    dispatch(loadActions.getLoadsByStatus({ ...filteredInfo, department: PAYABLE }, loader));
  };

  const handleOpenClose = (type) => {
    setOpen(type ? type : '');
  };

  const handleClearAllSelected = () => {
    handleGet();
    setSelectedLoads([]);
    setOpen('');
  };

  const removeAllSelects = () => {
    setSelectedLoads([]);
    setOpen('');
  };

  const handleClick = (item, type) => {
    setData(item);
    if (type === 'correction') {
      handleOpenClose('correction');
    }
  };

  const handleSend = async () => {
    setCorrectionLoad(true);
    try {
      const date = { 'correction': correction };
      await LoadServices.PayableCorrections(data.id, date);
      setCorrectionLoad(false);
      handleGet();
      handleOpenClose();
    } catch (e) {
      setCorrectionLoad(false);
    }
  };

  const handleSelect = (e, item) => {
    if (!selectedLoads?.length) {
      dispatch(loadActions.getLoadsByPayable(item?.id));
    }
    if (e.target.checked === true) {
      setSelectedLoads([...selectedLoads, item]);
    } else {
      const newArr = [];
      selectedLoads.filter((k, key) => (k.id !== item.id && newArr.push(k)));
      setSelectedLoads(newArr);
      if (newArr.length === 0) {
        handleGet();
      }
    }
  };

  const customChildren = (item) => {
    return (
      RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_CARRIER_PAYMENT?.code]) &&
      <TableCell style={{ padding: '8px 2px' }}>
        <Checkbox
          style={{ padding: '0 0 0 4px', color: Colors.ThemeBlue }} name={item?.id}
          checked={selectedLoads && !!selectedLoads.find((i) => i?.id === item?.id)}
          onClick={(e) => handleSelect(e, item)}
        />
      </TableCell>
    );
  };

  const handleChange = (value, name) => {
    const params = {
      ...info,
    };

    if (value) {
      params[name] = value.id;
    } else {
      delete params[name];
    }
    SavePage(history, info, { ...params });
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className={globalClasses.filtersWrapper}>
        <div className="align-center">
          <DateFilters status={PAYABLE} />
          {/*<ReceivablesNoteDrawer />*/}
          <UserInputsDropdown
            dropdownOptions={carriersList?.carriers}
            onPass={(option) => handleChange(option, 'carrier')}
            selected={info?.carrier ? info?.carrier : ''}
            noText={'All Carriers'}
            firstText={'companyName'}
          />
        </div>
        {RolePermission([PermissionsList?.ALL_ACCESS?.code, PermissionsList?.EXPORT_RECEIVABLE_CSV?.code]) &&
          <CsvFile params={payableParams} />
        }
      </div>

      <div className={globalClasses.totalWrapper}>
        <div className={globalClasses.centerItem}>
          <p className={globalClasses.totalTitle}>Totals</p>
          <div className={globalClasses.receivablesTotalsBox}>
            <TotalItems
              text={<PriceConvertor price={payableInfo?.current?.amount} />}
              count={` (${payableInfo?.current?.count || 0})`}
              title={'Current:'}
              color={'#00C8511A'}
            />
            <TotalItems
              text={<PriceConvertor price={payableInfo?.dueNow?.amount} />}
              count={` (${payableInfo?.dueNow?.count || 0})`}
              title={'Due Now:'}
              color={'#438AFE1A'}
            />
            <TotalItems
              text={<PriceConvertor price={payableInfo?.pastDue?.amount} />}
              count={` (${payableInfo?.pastDue?.count || 0})`}
              title={'Past Due:'}
              color={'#FFA3301A'}
            />
            <TotalItems
              text={<PriceConvertor price={payableInfo?.urgent?.amount} />}
              title={'Urgent:'}
              count={` (${payableInfo?.urgent?.count || 0})`}
              color={'#D725251A'}
            />
          </div>

        </div>
        <div>
          <TotalItems
            text={<PriceConvertor price={payableInfo?.grandTotal?.amount} />}
            count={` (${payableInfo?.grandTotal?.count || 0})`}
            title={'Total: '}
            color={'#E6ECF380'}
            styles={{ marginRight: 0 }}
          />
        </div>
      </div>

      {selectedLoads?.length ?
        <div className="fromTo">
          <div className={classes.selectedItemsWrapper}>
            <div className={classes.itemsInfo}>
              <CloseButton handleCLic={handleClearAllSelected} />
              <p>{selectedLoads?.length} item selected</p>
            </div>

            <AddModalButton
              buttonStyle={{ height: '36px' }}
              styles={{ marginTop: '0' }}
              handleClick={() => handleOpenClose('selected')}
              text={'Create Payment'}
            />
          </div>
        </div>
        : ''
      }

      <FullTable
        checkBox={true}
        head={payablesHead}
        body={payablesBody}
        loadingType={ACTION_TYPE}
        list={loadsList?.loads}
        listCount={!selectedLoads?.length ? loadsList?.count : ''}
        handleClick={(id) =>
          openPrimary(<LoadDetailSwitcher loadId={id} />)

          // history.push({
          //   pathname: `/load/${id}`,
          //   state: { detail: 'payableDetails', parent: '/payables' },
          // })
        }
        noText={'Payables'}
        handleClickButton={handleClick}
        handleCheckBox={handleSelect}
        customBodyItem={customChildren}
      />

      <SimpleModal
        handleOpenClose={handleOpenClose}
        openDefault={!!open}
        content={
          <>
            {open === 'correction' ?
              <CorrectionsModal
                handleChangeText={(e) => setCorrection(e)}
                loadingCor={correctionLoad}
                handleSend={handleSend}
                type={'notVerified'}
                handleClose={() => handleOpenClose()}
              />
              :
              open === 'selected' &&
              <PayableCreatePayment
                removeAllSelects={removeAllSelects}
                handleClose={() => handleOpenClose()}
                selectedLoads={selectedLoads}
              />
            }
          </>
        }
      />
    </div>
  );
};
import React from 'react';
import { AntSwitch, Images, PermissionsList, RolePermission } from 'utils';
import {
  DateRow,
  DeleteButton,
  LoadIconAndTitle,
  PriceRow,
  TextRow,
  TypeRow,
} from 'components';
import { vendorEnums } from 'pages';

export const company = [
  { name: 'LLC', id: 'LLC' },
  { name: 'Corporation', id: 'CORPORATION' },
  { name: 'DBA', id: 'DBA' },
  { name: '1099 Vendor', id: '1099_VENDOR' },
];

export const docker = [
  { name: 'Mc Number', id: 'MC_NUMBER' },
  // {name: 'Doc Number', id: 'DOT_NUMBER'},
  { name: 'Ca Number', id: 'CA_NUMBER' },
  { name: 'USDOT', id: 'USDOT' },
];

export const loadsHead = [
  { name: 'displayId', title: 'Load ID', custom: false },
  { name: 'createdDate', title: 'Created Date', smallSize: 7, bigSize: 8, icon: 'date', type: 'date' },
  { name: 'customer', nameRow: 'name', title: 'Customer', smallSize: 6, custom: false },
  { name: 'agent', nameRow: 'name', title: 'Agent', custom: false },
  { name: 'branch', nameRow: 'name', title: 'Branch', custom: false },
  { name: 'office', nameRow: 'name', title: 'Office', custom: false },
  { name: 'customerRate', title: 'Customer Rate', custom: false, smallSize: 7, bigSize: 8 },
  { name: 'carrierRate', title: 'Vendor Rate', custom: false, smallSize: 7, bigSize: 8 },
  {
    name: '', title: 'Status',
    filterList: [
      { name: 'All', id: '' },
      { name: 'In Transit', id: 'INTRANSIT' },
      { name: 'Processing', id: 'PROCESSING' },
      { name: 'Paid', id: 'PAID' },
      { name: 'Denied', id: 'DENIED' },
      { name: 'Rejected', id: 'REJECTED' },
      { name: 'Pending', id: 'PENDING' },
      { name: 'Credit Check', id: 'CREDITCHECK' },
      { name: 'Consingment', id: 'CONSIGNMENT' },
      { name: 'Complete', id: 'COMPLETE' },
    ],
  },
];

export const loadsBody = [
  { rowText: (item) => <LoadIconAndTitle icon={Images.loadOutline} title={item?.displayId} item={item} /> },
  // {rowText: (item) => <IconAndTitle icon={Images.loadOutline} title={item?.displayId}/>},
  { rowText: (item) => <DateRow date={item?.createdDate} /> },
  { rowText: (item) => <TextRow name={item?.customer?.name} /> },
  { rowText: (item) => <TextRow name={item?.agent ? item?.agent?.name : 'Not Set'} /> },
  { rowText: (item) => <TextRow name={item?.branch ? item?.branch?.name : 'Not Set'} /> },
  { rowText: (item) => <TextRow name={item?.office ? item?.office?.name : 'Not Set'} /> },
  { rowText: (item) => <PriceRow info={item?.bill?.totalRate} /> },
  { rowText: (item) => <PriceRow info={item?.payable?.totalRate} /> },
  {
    rowText: (item) =>
      <p
        className={item?.status === 'PROCESSING' ? 'processing-icon-color' :
          item?.status === 'PENDING' ? 'pending-icon-color' :
            item?.status === 'INTRANSIT' ? 'inTransit-icon-color' :
              item?.status === 'REJECTED' ? 'rejected-icon-color' :
                item?.status === 'COMPLETED' ? 'completed-icon-color' :
                  ''}
      >
        {
          item?.status === 'INTRANSIT' ? 'In Transit' :
            item?.status === 'CREDITCHECK' ? 'Credit Check' :
              item?.status === 'CONSIGNMENT_REQUEST' ? 'Consignment Request' :

                <TypeRow text={item?.status} />
        }
      </p>,
  },
];

export const ACTION_TYPE = 'GET_LOAD_CARRIER';

export const equipmentHead = [
  {
    name: 'driverType', title: 'Driver Type',
    filterList: [
      { name: 'All', id: '' },
      { name: 'Solo', id: 'Solo' },
      { name: 'Team', id: 'Team' },
    ],
  },
  {
    name: 'truckType', title: 'Truck Type',
    filterList: [
      { name: 'All', id: '' },
      { name: 'Solo', id: 'Solo' },
      { name: 'FlatBed', id: 'FlatBed' },
      { name: 'Power Only', id: 'PowerOnly' },
    ],
  },
  {
    name: 'size', title: 'Size',
    filterList: [
      { name: 'All', id: '' },
      { name: '24', id: '24' },
      { name: '48', id: '48' },
      { name: '53', id: '53' },
    ],
  },
  RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
  { name: 'action', title: 'Action', search: 'false', custom: false },
];

export const equipmentBody = [
  { rowText: (item) => <TypeRow text={item?.driverType} /> },
  { rowText: (item) => <TypeRow text={item?.truckType} /> },
  { rowText: (item) => <TextRow name={item?.size + ''} /> },
  {
    button: (item, info, load) =>
      <div>
        {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
          <div>{
            <div className="align-center">

              <div className={'align-center'} style={{ marginLeft: '10px' }}>
                                <span
                                  className="small-switch-text">{info?.status === 'ACTIVE' ? 'Activate' : 'Inactivate'}</span>
                <AntSwitch
                  onClick={() => item('switch')}
                  checked={info?.status === 'ACTIVE'}
                />
              </div>
              <DeleteButton toolTipTitle={'Remove Role'} handleClick={() => item('delete')} />
            </div>
          }</div>

        }</div>
    , notClickable: true,
  },
];

export const EQUIPMENT_ACTION_TYPE = 'GET_EQUIPMENT';

// Documents


export const carrierUploadDockTypes = [
  { name: 'Proof of insurance', id: 'PROOF_OF_INSURANCE' },
  { name: 'Company Packet', id: 'CARRIERPACKET' },
  { name: 'W9', id: 'W9' },
  { name: 'MC Certificate', id: 'MC_CERTIFICATE' },
  { name: 'Interstate Permit', id: 'INTERSTATE_PERMIT' },
  { name: 'CDL', id: 'CDL' },
  { name: 'EFS Form', id: 'EFS_FORM' },
  { name: 'Payment Form', id: 'PAYMENT_FORM' },
  { name: 'Setup Packet', id: 'SETUPPACKET' },
  { name: 'NOA', id: 'NOA' },
  { name: 'LOR', id: 'LOR' },
  { name: 'Written consent', id: 'WRITTEN_CONSENT' },
  { name: 'Voided check', id: 'VOIDED_CHECK' },
  { name: 'Other', id: 'OTHER' },
];

export const carrierDockTypes = [
  { name: 'All Documents', id: 'All DocumentsSection' },
  ...carrierUploadDockTypes,
];


// Collections Table

export const collectionsHead = [
  { name: '', title: 'Load ID', custom: false },
  { name: '', title: 'Amount', custom: false },
  { name: '', title: 'Collected', custom: false },
  { name: '', title: 'Remaining', custom: false },
  { name: '', title: 'Comments', custom: false },
];

export const collectionsBody = [
  { rowText: (item) => item?.loadDisplayId },
  { rowText: (item) => <PriceRow info={item?.amount ? item?.amount : 0} /> },
  { rowText: (item) => <PriceRow info={item?.collected ? item?.collected : 0} /> },
  { rowText: (item) => <PriceRow info={item?.amount - item?.collected} /> },
  { rowText: (item) => <TextRow name={item?.comment ? item.comment : 'Not Set'} /> },
];


export const lastLoadsHead = [
  { name: '', title: 'Pick Up Date', custom: false },
  { name: '', title: 'Pick Up State', custom: false },
  { name: '', title: 'Drop Up Date', custom: false },
  { name: '', title: 'Drop Up State', custom: false },
  { name: '', title: 'Vendor Rate', custom: false },
];

export const lastLoadsBody = [
  { rowText: (item) => <DateRow date={item?.pickupDate} /> },
  { rowText: (item) => <TextRow name={item?.pickupState} /> },
  { rowText: (item) => <DateRow date={item?.dropoffDate} /> },
  { rowText: (item) => <TextRow name={item?.dropoffState} /> },
  { rowText: (item) => <PriceRow info={+item?.carrierRate} /> },
];

export const LAST_LOADS = 'GET_CARRIER_LAST_LOAD';

export const Classification = [
  { name: 'LLC', id: 'LLC' },
  { name: 'Individual Sole', id: 'INDIVIDUAL_SOLE' },
  { name: 'C Corporation', id: 'C_CORPORATION' },
  { name: 'S Corporation', id: 'S_CORPORATION' },
  { name: 'Partnership', id: 'PARTNERSHIP' },
  { name: 'Trust Estate', id: 'TRUST_ESTATE' },
];

const companyMcTypes = {
  MC_NUMBER: 'MC_NUMBER',
  CA_NUMBER: 'CA_NUMBER',
  USDOT: 'USDOT',
  MC_B: 'MC_B',
  FF: 'FF',
  NVOCC: 'NVOCC',
  VOCC: 'VOCC',
  OTI: 'OTI',
  IATA: 'IATA',
};

export const carrierDockTypeENums = {
  SETUPPACKET: 'SETUPPACKET',
  CO_BROKER_PACKET: 'CO_BROKER_PACKET',
  WAREHOUSE_PACKET: 'WAREHOUSE_PACKET',
  CUSTOM_PACKET: 'CUSTOM_PACKET',
  FF_PACKET: 'FF_PACKET',
  INT_FF_PACKET: 'INT_FF_PACKET',
  FACTORING_PACKET: 'FACTORING_PACKET',
  VENDOR_PACKET: 'VENDOR_PACKET',


  CARRIERPACKET: 'CARRIERPACKET',


  INTERSTATE_PERMIT: 'INTERSTATE_PERMIT',
  NVOCC_PERMIT: 'NVOCC_PERMIT',
  VOCC_PERMIT: 'VOCC_PERMIT',
  OTI_PERMIT: 'OTI_PERMIT',

  PROOF_OF_INSURANCE: 'PROOF_OF_INSURANCE',

  W9: 'W9',
  MC_CERTIFICATE: 'MC_CERTIFICATE',

  CDL: 'CDL',
  EFS_FORM: 'EFS_FORM',
  PAYMENT_FORM: 'PAYMENT_FORM',
  NOA: 'NOA',
  LOR: 'LOR',
  WRITTEN_CONSENT: 'WRITTEN_CONSENT',
  VOIDED_CHECK: 'VOIDED_CHECK',
  LOAD_CONSENT_FORM: 'LOAD_CONSENT_FORM',
  SURETY_BOND: 'SURETY_BOND',
  FMC_SCREENSHOT: 'FMC_SCREENSHOT',
  BUSINESS_LICENSE: 'BUSINESS_LICENSE',

  OTHER: 'OTHER',


  MC_PERMIT: 'MC_PERMIT',
  MC_B_PERMIT: 'MC_B_PERMIT',
  CBP_PERMIT: 'CBP_PERMIT',
  FF_PERMIT: 'FF_PERMIT',
  MC: 'MC',
  USDOT: 'USDOT',


};

export const RenderDetails = (type) => {

  switch (type) {
    case vendorEnums.CARRIER:
      return {
        Information: {
          name: 'Carrier Information',
          number: 1,
          companyTypeList: [
            { name: 'Mc Number', id: companyMcTypes.MC_NUMBER },
            { name: 'Ca Number', id: companyMcTypes.CA_NUMBER },
            { name: 'USDOT', id: companyMcTypes.USDOT },
          ],
          authorityNumberName: 'Authority Number*',
          expDate: 'Insurance Exp Date',
        },
        w9: {
          name: 'W9 Information',
          number: 2,
        },
        payment: {
          name: 'Payment Setup',
          number: 3,
          EFS: true,
          CHECK: true,
          ACH: true,
          FACTORING: true,
        },
        assignment: {
          name: 'Carrier Assignment',
          number: 4,
        },
        access: {
          name: 'Carrier Access',
          number: 5,
        },
        documents: {
          name: 'Documents',
          number: 6,
          types: [
            { name: 'Setup Packet', id: carrierDockTypeENums.SETUPPACKET },
            { name: 'Proof of insurance', id: carrierDockTypeENums.PROOF_OF_INSURANCE },
            { name: 'MC Permit', id: carrierDockTypeENums.MC_PERMIT },
            { name: 'W9', id: carrierDockTypeENums.W9 },
            { name: 'CDL', id: carrierDockTypeENums.CDL },
            { name: 'Load Consent Form', id: carrierDockTypeENums.LOAD_CONSENT_FORM },
            { name: 'NOA', id: carrierDockTypeENums.NOA },
            { name: 'LOR', id: carrierDockTypeENums.LOR },
            { name: 'Payment Form', id: carrierDockTypeENums.PAYMENT_FORM },
            { name: 'Voided check', id: carrierDockTypeENums.VOIDED_CHECK },
            { name: 'Other', id: carrierDockTypeENums.OTHER },
          ],
        },
      };


    case vendorEnums.CO_BROKER:
      return {
        Information: {
          name: 'Co-Broker Information',
          number: 1,
          noDOB: true,
          noDL: true,
          noIssIngState: true,
          companyTypeList: [
            { name: 'Mc B', id: companyMcTypes.MC_NUMBER },
            { name: 'USDOT', id: companyMcTypes.USDOT },
          ],
          authorityNumberName: 'Authority Number*',
          expDate: 'Surety Bond Exp',
        },
        w9: {
          name: 'W9 Information',
          number: 2,
        },
        payment: {
          name: 'Payment Setup',
          number: 3,
          EFS: true,
          CHECK: true,
          ACH: true,
          FACTORING: true,
        },
        assignment: {
          name: 'Co-Broker Assignment',
          number: 4,
        },
        access: {
          name: 'Co-Broker Access',
          number: 5,
        },
        documents: {
          name: 'Documents',
          number: 6,
          types: [
            { name: 'Co-Broker Packet', id: carrierDockTypeENums.CO_BROKER_PACKET },
            { name: 'Proof of insurance', id: carrierDockTypeENums.PROOF_OF_INSURANCE },
            { name: 'MC-B Permit', id: carrierDockTypeENums.MC_B_PERMIT },
            { name: 'W9', id: carrierDockTypeENums.W9 },
            { name: 'Load Consent Form', id: carrierDockTypeENums.LOAD_CONSENT_FORM },
            { name: 'Surety Bond', id: carrierDockTypeENums.SURETY_BOND },
          ],
        },
      };


    case vendorEnums.WAREHOUSE:
      return {
        Information: {
          name: 'Warehouse Information',
          number: 1,
          noDOB: true,
          noDL: true,
          noIssIngState: true,
          companyTypeList: [
            { name: 'Mc Number', id: companyMcTypes.MC_NUMBER },
            { name: 'USDOT', id: companyMcTypes.USDOT },
          ],
          authorityNumberName: 'Authority Number*',
          expDate: 'Insurance Exp Date',
          businessLicense: true,
        },
        w9: {
          name: 'W9 Information',
          number: 2,
        },
        payment: {
          name: 'Payment Setup',
          number: 3,
          EFS: true,
          CHECK: true,
          ACH: true,
          FACTORING: true,
        },
        assignment: {
          name: 'Warehouse Assignment',
          number: 4,
        },
        access: {
          name: 'Warehouse Access',
          number: 5,
        },
        documents: {
          name: 'Documents',
          number: 6,
          types: [
            { name: 'Warehouse Packet', id: carrierDockTypeENums.WAREHOUSE_PACKET },
            { name: 'Proof of insurance', id: carrierDockTypeENums.PROOF_OF_INSURANCE },
            { name: 'W9', id: carrierDockTypeENums.W9 },
            { name: 'Load Consent Form', id: carrierDockTypeENums.LOAD_CONSENT_FORM },
            { name: 'Business License', id: carrierDockTypeENums.BUSINESS_LICENSE },
          ],
        },
      };

    case vendorEnums.CUSTOM_BROKER:
      return {
        Information: {
          name: 'Custom-Broker Information',
          number: 1,
          noDOB: true,
          noDL: true,
          noIssIngState: true,
          authorityNumberName: 'CBP Licence Number*',
          expDate: 'Insurance Exp Date',
          businessLicense: true,
        },
        w9: {
          name: 'W9 Information',
          number: 2,
        },
        payment: {
          name: 'Payment Setup',
          number: 3,
          noFuelCard: true,
          EFS: true,
          CHECK: true,
          ACH: true,
          FACTORING: true,
        },
        assignment: {
          name: 'Custom-Broker Assignment',
          number: 4,
        },
        access: {
          name: 'Custom-Broker Access',
          number: 5,
        },
        documents: {
          name: 'Documents',
          number: 6,
          types: [
            { name: 'Custom Packet', id: carrierDockTypeENums.CUSTOM_PACKET },
            { name: 'Proof of insurance', id: carrierDockTypeENums.PROOF_OF_INSURANCE },
            { name: 'CBP Permit', id: carrierDockTypeENums.CBP_PERMIT },
            { name: 'W9', id: carrierDockTypeENums.W9 },
            { name: 'Load Consent Form', id: carrierDockTypeENums.LOAD_CONSENT_FORM },
            { name: 'Business License', id: carrierDockTypeENums.BUSINESS_LICENSE },
          ],
        },
      };

    case vendorEnums.FREIGHT_FORWARDER:
      return {
        Information: {
          name: 'Freight Forwarder Information',
          number: 1,
          noDOB: true,
          noDL: true,
          noIssIngState: true,
          companyTypeList: [
            { name: 'FF', id: companyMcTypes.FF },
            { name: 'USDOT', id: companyMcTypes.USDOT },
          ],
          authorityNumberName: 'FF Number*',
          expDate: 'Insurance Exp Date',
        },
        w9: {
          name: 'W9 Information',
          number: 2,
        },
        payment: {
          name: 'Payment Setup',
          number: 3,
          noFuelCard: true,
          WIRE: true,
          EFS: true,
          CHECK: true,
          ACH: true,
          FACTORING: true,
        },
        assignment: {
          name: 'Freight Forwarder Assignment',
          number: 4,
        },
        access: {
          name: 'Freight Forwarder Access',
          number: 5,
        },
        documents: {
          name: 'Documents',
          number: 6,
          types: [
            { name: 'FF Packet', id: carrierDockTypeENums.FF_PACKET },
            { name: 'Proof of insurance', id: carrierDockTypeENums.PROOF_OF_INSURANCE },
            { name: 'FF Permit', id: carrierDockTypeENums.FF_PERMIT },
            { name: 'W9', id: carrierDockTypeENums.W9 },
            { name: 'Load Consent Form', id: carrierDockTypeENums.LOAD_CONSENT_FORM },
          ],
        },
      };

    case vendorEnums.INTERMODAL_FREIGHT_FORWARDER:
      return {
        Information: {
          name: 'Int.Freight Information',
          number: 1,
          noDOB: true,
          noDL: true,
          noIssIngState: true,
          companyTypeList: [
            { name: 'Mc Number', id: companyMcTypes.MC_NUMBER },
            { name: 'NVOCC', id: companyMcTypes.NVOCC },
            { name: 'VOCC', id: companyMcTypes.VOCC },
            // { name: 'VOCC', id: companyMcTypes.VOCC },
            { name: 'OTI', id: companyMcTypes.OTI },
            { name: 'IATA', id: companyMcTypes.IATA },
          ],
          authorityNumberName: 'Authority Number*',
          expDate: 'Surety Bond Ep',
          businessLicense: true,
        },
        // w9: {
        //     name: 'W9 Information',
        //     number: 2
        // },
        payment: {
          name: 'Payment Setup',
          number: 2,
          noFuelCard: true,
          WIRE: true,
          EFS: true,
          CHECK: true,
          ACH: true,
          FACTORING: true,
        },
        assignment: {
          name: 'Int.Freight Assignment',
          number: 3,
        },
        access: {
          name: 'Int.Freight Access',
          number: 4,
        },
        documents: {
          name: 'Documents',
          number: 5,
          types: [
            { name: 'Int. FF Packet', id: carrierDockTypeENums.INT_FF_PACKET },
            { name: 'Proof of insurance', id: carrierDockTypeENums.PROOF_OF_INSURANCE },
            { name: 'NVOCC Permit', id: carrierDockTypeENums.NVOCC_PERMIT },
            { name: 'VOCC Permit', id: carrierDockTypeENums.VOCC_PERMIT },
            { name: 'OTI Permit', id: carrierDockTypeENums.OTI_PERMIT },
            { name: 'MC', id: carrierDockTypeENums.MC },
            { name: 'USDOT', id: carrierDockTypeENums.USDOT },
            { name: 'Load Consent Form', id: carrierDockTypeENums.LOAD_CONSENT_FORM },
            { name: 'Surety Bond', id: carrierDockTypeENums.SURETY_BOND },
            { name: 'FMC Screenshot', id: carrierDockTypeENums.FMC_SCREENSHOT },
            { name: 'Business License', id: carrierDockTypeENums.BUSINESS_LICENSE },
          ],
        },
      };

    case vendorEnums.FACTORED:
      return {
        Information: {
          name: 'Factor Carrier Information',
          number: 1,
          companyTypeList: [
            { name: 'Mc Number', id: companyMcTypes.MC_NUMBER },
            { name: 'Ca Number', id: companyMcTypes.CA_NUMBER },
            { name: 'USDOT', id: companyMcTypes.USDOT },
          ],
          authorityNumberName: 'Authority Number*',
          expDate: 'Insurance Exp Date',
        },
        w9: {
          name: 'W9 Information',
          number: 2,
        },
        payment: {
          name: 'Payment Setup',
          number: 3,
          noFuelCard: true,
          CHECK: true,
          ACH: true,
        },
        assignment: {
          name: 'Factor Carrier Assignment',
          number: 4,
        },
        access: {
          name: 'Factor Carrier Access',
          number: 5,
        },
        documents: {
          name: 'Documents',
          number: 6,
          types: [
            { name: 'Factoring Packet', id: carrierDockTypeENums.FACTORING_PACKET },
            { name: 'Proof of insurance', id: carrierDockTypeENums.PROOF_OF_INSURANCE },
            { name: 'MC Permit', id: carrierDockTypeENums.MC_PERMIT },
            { name: 'W9', id: carrierDockTypeENums.W9 },
            { name: 'CDL', id: carrierDockTypeENums.CDL },
            { name: 'Load Consent Form', id: carrierDockTypeENums.LOAD_CONSENT_FORM },
            { name: 'NOA', id: carrierDockTypeENums.NOA },
            { name: 'LOR', id: carrierDockTypeENums.LOR },
          ],
        },
      };


    case vendorEnums.VENDOR:
      return {
        Information: {
          name: 'Vendor Information',
          number: 1,
          noAuthority: true,
          expDate: 'Insurance Exp Date',
          businessLicense: true,
        },
        w9: {
          name: 'W9 Information',
          number: 2,
        },
        payment: {
          name: 'Payment Setup',
          number: 3,
          noFuelCard: true,
          EFS: true,
          CHECK: true,
          ACH: true,
          FACTORING: true,
        },
        assignment: {
          name: 'Vendor Assignment',
          number: 4,
        },
        access: {
          name: 'Vendor Access',
          number: 5,
        },
        documents: {
          name: 'Documents',
          number: 6,
          types: [
            { name: 'Vendor Packet', id: carrierDockTypeENums.VENDOR_PACKET },
            { name: 'Proof of insurance', id: carrierDockTypeENums.PROOF_OF_INSURANCE },
            { name: 'W9', id: carrierDockTypeENums.W9 },
            { name: 'CDL', id: carrierDockTypeENums.CDL },
            { name: 'Business Lincese', id: carrierDockTypeENums.BUSINESS_LICENSE },
          ],
        },
      };


    case vendorEnums.INHOUSE_PROFILE:
      return {
        Information: {
          name: 'In House Profile',
          number: 1,
          noAuthority: true,
        },
        payment: {
          name: 'Payment Setup',
          number: 2,
          noFuelCard: true,
          EFS: true,
          CHECK: true,
          ACH: true,
          FACTORING: true,
        },
        assignment: {
          name: 'In House Profile Assignment',
          number: 3,
        },
        access: {
          name: 'In House Profile Access',
          number: 4,
        },
      };

    default:
      return {};
  }
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { agentActions, httpRequestsOnSuccessActions, loadActions, mcActions } from 'store';
import {
  LoadDetails,
  CustomerCredit,
  Destination,
  RequestACarrier,
  DocumentsSection,
  Processing,
} from './core';
import { officeManager, PermissionsList, RolePermission, useGlobalStyles, useModal, userType } from 'utils';
import { CANCELLED, CREDITCHECK, DENIED, REJECTED } from '../../../pages/accounting/constants';
import { LoadErrAndNotes } from './core/common/loadErrAndNotes';
import { CONSIGNMENT, CONSIGNMENT_REQUEST } from '../../../pages/credit/constants';
import { LoadSummary } from './core/loadSummary';
import { StopType } from 'utils/types';
import { LoadActions } from './core/loadActions';
import { loadTypeRequireStop } from './core/common/constants';
import { CustomDeleteModal } from '../../modals';
import { checkCancelLoad } from './core/constants';
import { RENDER_LOADS } from '../../../pages';
import { wrapperStyle } from '../../../components/wrapper/styles';

const CANCEL_ACTION_TYPE = 'CANCEL_LOAD';
export const CreateLoadTable = ({ groupId }) => {
  const { mcsList, loadById, carriersList, assignedAgentMc } = useSelector((state) => ({
    mcsList: state.mcs.mcsList,
    assignedAgentMc: state.mcs.assignedAgentMc,
    carriersList: state.carriers.carriersList,
    loadById: state.loads.loadById,
  }));
  const userInfo = JSON.parse(localStorage.getItem('poloUserInfo'));
  const dispatch = useDispatch();
  const [loadDetails, setLoadDetails] = useState(false);
  const [destination, setDestination] = useState(false);
  const [requestACarrier, setRequestACarrier] = useState(false);
  const [documents, setDocuments] = useState(false);
  const [tonu, setTonu] = useState( loadById?.isTonu || false);
  const drop = loadById && loadById?.stops?.length && loadById?.stops.filter((i) => i.type === StopType.DROPOFF);
  const pick = loadById && loadById?.stops?.length && loadById?.stops.filter((i) => i.type === StopType.PICKUP);
  const { open, close } = useModal();
  const checkRequiredStops = loadById?.type === loadTypeRequireStop.DRY ||
    loadById?.type === loadTypeRequireStop.REEFER ||
    loadById?.type === loadTypeRequireStop.BOX_TRUCK ||
    loadById?.type === loadTypeRequireStop.POWER ||
    loadById?.type === loadTypeRequireStop.FLATBED ||
    loadById?.type === loadTypeRequireStop.AUTO_CARRIER;

  const classes = wrapperStyle();


  useEffect(() => {
    dispatch(loadActions.removeSearchedList());
    dispatch(agentActions.getAgents({ status: 'ACTIVE' }));
    if (userType === 'ADMIN' && !loadById) {
      dispatch(mcActions.getMcs({ status: 'ACTIVE' }));
    }
  }, []);

  useEffect(() => {
    if (userInfo && userType !== 'ADMIN' || !officeManager) {
      dispatch(mcActions.getAgentAssignedMcs(userInfo?.id, 'AGENT'));
    }
  }, []);

  useEffect(() => {
    if (loadById && loadById.isTonu === true) {
      setLoadDetails(true);
      setDestination(true);
      setRequestACarrier(true);
      setDocuments(true);
    } else {
      if (loadById?.status !== CREDITCHECK) {
        if (loadById?.mc?.id
          && loadById?.status !== REJECTED
          && loadById?.status !== CONSIGNMENT
          && loadById?.status !== CONSIGNMENT_REQUEST
          && loadById?.status !== DENIED && (loadById?.status === CANCELLED ? loadById?.type : true)
        ) {
          setLoadDetails(true);
        }
        if (loadById?.type) {
          setDestination(true);
        } else {
          setDestination(false);
        }
        if (checkRequiredStops ? (loadById?.stops?.length > 0 && drop?.length && pick?.length) : !!loadById?.type) {
          setRequestACarrier(true);
        } else {
          setRequestACarrier(false);
        }
        if (loadById?.carrier?.id && (checkRequiredStops ? (loadById?.stops?.length > 0 && drop?.length && pick?.length) : true)) {
          setDocuments(true);
        } else {
          setDocuments(false);
        }
      }
    }
  }, [loadById]);

  return (
    <div>

      {!loadById?.id ?
        <div className={classes.createOfficesBody}>
          <CustomerCredit
            groupId={groupId}
            tonu={tonu}
            info={loadById}
            handleSwitchTonu={() => setTonu(!tonu)}
            handleChangeScreen={() => setLoadDetails(
              loadById?.status !== 'CREDITCHECK' &&
              loadById?.status !== 'REJECTED' &&
              loadById?.status !== 'CONSIGNMENT' &&
              loadById?.status !== 'CONSIGNMENT_REQUEST' &&
              loadById?.status !== 'DENIED',
            )}
            loadDetails={loadDetails}
          />
        </div>

        :

        <div>
          {loadById?.status !== CANCELLED && <LoadActions loadById={loadById} />}
          <LoadErrAndNotes loadById={loadById} />
          {loadById?.status && <LoadSummary loadById={loadById} />}
          <CustomerCredit
            tonu={tonu}
            handleSwitchTonu={() => setTonu(!tonu)}
            handleChangeScreen={() => setLoadDetails(
              loadById?.status !== 'CREDITCHECK' &&
              loadById?.status !== 'REJECTED' &&
              loadById?.status !== 'CONSIGNMENT' &&
              loadById?.status !== 'CONSIGNMENT_REQUEST' &&
              loadById?.status !== 'DENIED',
            )}
            loadDetails={loadDetails}
          />
          {loadDetails &&
            <LoadDetails
              tonu={tonu}
              mcsListReserve={userType === 'ADMIN' ? mcsList?.mcs : assignedAgentMc}
              destination={destination}
              handleChangeScreen={() => setDestination(true)}
            />
          }
          {destination &&
            <Destination
              drop={drop}
              pick={pick}
              destination={requestACarrier}
              handleChangeScreen={() => setRequestACarrier(true)}
            />
          }
          {requestACarrier &&
            <RequestACarrier
              tonu={tonu}
              carriers={carriersList?.carriers}
              destination={documents}
              handleChangeScreen={() => setDocuments(true)}
            />
          }
          {documents && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.UPLOAD_LOAD_DOCUMENT?.code], 'agentView') &&
            <DocumentsSection />
          }
          {loadById && loadById.status === 'PROCESSING' && loadById?.status !== CANCELLED &&
            <Processing info={loadById} />
          }

          {checkCancelLoad(loadById) &&
            <div className="cancel-load-btn-box">
              <button
                className="cancel-btn"
                onClick={() => open(
                  <CustomDeleteModal
                    afterSuccess={() => {
                      close();
                      dispatch(httpRequestsOnSuccessActions.appendSuccess(RENDER_LOADS));
                    }}
                    actionType={CANCEL_ACTION_TYPE}
                    text="Cancel Load?"
                    handleDel={() => dispatch(loadActions.cancelLoad(loadById?.id))}
                    modalText={'Are you sure you want to cancel this load?'}
                    cancelButton={'Cancel'}
                    closeButton={'Go Back'}
                  />,
                )}
              >
                Cancel Load
              </button>
            </div>
          }
        </div>
      }
    </div>
  );
};
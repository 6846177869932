import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  AntSwitch,
  Colors,
  ErrorText, isFactored,
  useGlobalStyles,
  useGlobalText, usePrimaryModal,
} from 'utils';
import {
  Circle,
  CreateChancel, EditSaveButtons, ErrMessage,
  Line,
  SelectTypeAutocomplete,
  ValidationInput,
} from 'components';
import { createLoadStyle } from './styles';
import { Button } from '@material-ui/core';
import { httpRequestsOnSuccessActions, loadActions, LoadServices } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { CANCELLED, CREDITCHECK, DENIED, REJECTED } from '../../../../pages/accounting/constants';
import { CONSIGNMENT, CONSIGNMENT_REQUEST } from '../../../../pages/credit/constants';
import { RENDER_LOADS } from '../../../../pages';

const ACTION_TYPE = 'LOAD_DETAILS';
export const CustomerCredit = ({
                                 info,
                                 handleSwitchTonu,
                                 customers,
                                 tonu,
                               }) => {
  const { groupById } = useSelector((state) => ({
    groupById: state.loads.groupById,
  }));
  const globalStyles = useGlobalStyles();
  const globalText = useGlobalText();
  const classes = createLoadStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [backError, setBackError] = useState('');
  const [loader, setLoader] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [disables, setDisabled] = useState(!!info);
  const [inputs, setInputs] = useState({});
  const checkAwaiting =
    info?.status === CREDITCHECK ||
    info?.status === REJECTED ||
    info?.status === CONSIGNMENT ||
    info?.status === CONSIGNMENT_REQUEST ||
    info?.status === DENIED;
  const loadDetails = info?.id;
  const params = useParams();
  const fromGroup = !!params?.group;
  const { closePrimary } = usePrimaryModal();

  const selectedCustomer = [
    {
      name: fromGroup ? groupById?.customer?.name : info?.customer?.name,
      id: fromGroup ? groupById?.customer?.id : info?.customer?.id,
      mc: fromGroup ? groupById?.customer?.mc : info?.customer?.mc,
    },
  ];

  useEffect(() => {
    if (groupById?.id && fromGroup) {
      setInputs({
        'mc': groupById?.mc?.id,
        'customer': groupById?.customer?.id,
        'contactName': groupById?.contactName,
        'customerReference': groupById?.customerReference,
      });
    }
  }, [groupById]);

  useEffect(() => {
    if (params?.group) {
      dispatch(loadActions.getGroupById(params?.group));
    }
  }, []);

  useEffect(() => {
    if (info?.customer?.id) {
      setInputs({
        'customer': info.customer,
        'customerRate': +info.customerRate,
        'contactName': info.contactName,
        'customerReference': info.customerReference,
      });
    }
  }, [info]);

  const handleChange = e => {
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    if (error === e.target.name) {
      setError('');
      setBackError(null);
    }
    error === 'tonuValue' && setError('');
    setError('');
  };

  const handleTonu = () => {
    handleSwitchTonu(!tonu);
  };

  const handleCreate = async () => {
    if (inputs.customer && inputs.customerRate && inputs?.contactName && inputs?.customerReference) {
      const info = {
        'customer': inputs.customer.id ? inputs.customer.id : inputs.customer,
        'customerRate': +inputs.customerRate,
        'contactName': inputs.contactName,
        'customerReference': inputs.customerReference,
        'isTonu': tonu,
      };

      if (tonu === true ? inputs.customerRate <= 1000 : true) {
        setLoader(true);
        try {
          let res = await LoadServices.CreateFactoredLoad(info);
          dispatch(loadActions.getLoadById(res?.data?.id, 'noLoad'));
          dispatch(httpRequestsOnSuccessActions.appendSuccess(RENDER_LOADS));
          // history.push(`/loads`);
          // history.push(`/load/${res.data.id}`);
          setLoader(false);
          setDisabled(true);
        } catch (e) {
          setBackError(e?.data?.message);
          setLoader(false);
        }
      } else {
        setError('tonuValue');
      }
    } else {
      setError(
        !inputs.customer ? 'customer' :
          !inputs.customerRate ? 'customerRate' :
            !inputs.contactName ? 'contactName' :
              !inputs.customerReference ? 'customerReference' :
                '',
      );
    }
  };

  const handle = (e) => {
    if (e.target.value.length > 3) {
      setTimeout(() => {
        dispatch(loadActions.searchList(e.target.value));
      }, 500);
    }
  };


  const editLoadCredit = async () => {
    if (inputs?.contactName && inputs?.customerReference) {
      const sendInfo = {
        'contactName': inputs.contactName,
        'customerReference': inputs.customerReference,
      };
      setEditLoader(true);
      try {
        let res = await LoadServices.EditFactoredLoad(info?.id, sendInfo);
        dispatch(loadActions.getLoadById(res.data.id, 'noLoad'));
        dispatch(httpRequestsOnSuccessActions.appendSuccess(ACTION_TYPE));
        setEditLoader(false);
        setDisabled(true);
      } catch (e) {
        setBackError(e?.data?.message);
        setEditLoader(false);
      }
    } else {
      setError(
        !inputs.contactName ? 'contactName' :
          !inputs.customerReference ? 'customerReference' :
            '',
      );
    }
  };

  return (
    <div>
      <div className={classes.titleAndSwitcher}>
        <div className={globalStyles.centerItem}>
          <Circle number={1} back={Colors.ThemeAqua} />
          <p className={globalText.title}>Customer Credit Request</p>
        </div>
        <div className={globalStyles.centerItem} style={{ gap: 16 }}>
          {info?.status !== CANCELLED && info?.status !== CREDITCHECK && info?.status !== REJECTED && loadDetails && isFactored &&
            <>
              <EditSaveButtons
                type={ACTION_TYPE}
                handleChancel={() => setDisabled(true)}
                handleSetEdit={() => setDisabled(false)}
                handleSaveInfo={editLoadCredit}
                tableType={'load'}
                loadLoading={editLoader}
              />
            </>
          }
          <div className={globalStyles.centerItem}>
            <span style={{ width: 'auto' }} className={globalText.smallSwitchText}>{'TONU'}</span>
            <AntSwitch
              disabled={
                info?.isTonu ? true :
                  info?.customer?.id ? true :
                    inputs.customerRate ? inputs.customerRate > 1000 : true
              }
              onClick={handleTonu}
              checked={tonu}
            />
          </div>
        </div>
      </div>
      <div className={globalStyles.flexAndFullWidth}>
        {loadDetails &&
          <div className={'MuiAccordionDetails-root'}>
            <Line height={'auto'} />
          </div>
        }
        <div style={{ width: '100%', marginLeft: !loadDetails ? '48px' : 0, marginBottom: '30px' }}>
          <div className="flex" style={{ gap: 24, marginTop: 24 }}>
            <SelectTypeAutocomplete
              style={globalStyles.simpleInputFull}
              loadType={'GET_SEARCH_LOAD_CUSTOMERS'}
              name={'customer'}
              label={'name'}
              type={'mc'}
              title={'Customer (Name/MC)*'}
              handleSelect={handleChange}
              defaultValue={inputs?.customer?.id ? inputs.customer.id : inputs.customer}
              list={
                fromGroup ? selectedCustomer :
                  info ? selectedCustomer :
                    customers?.length ? customers : []
              }
              handleType={handle}
              disabled={!!inputs?.customer?.id || fromGroup}
              typeError={backError === 'Cannot use this customer due to auto deny' ? 'Cannot use this customer due to auto deny' : ''}
              error={error === 'customer' ? 'customer' :
                backError === 'Cannot use this customer due to auto deny' ? 'customer' : ''
              }
            />
            <ValidationInput
              className={inputs.customerRate && globalStyles.inputTextFieldBlue}
              variant={'outlined'}
              name={'customerRate'}
              label={'Customer Rate*'}
              type={'number'}
              typeError={
                error === 'customerRate' ? ErrorText.field :
                  error === 'tonuValue' ? 'Customer Rate mut be less or equal 1000' :
                    ''}
              onChange={handleChange}
              value={inputs.customerRate === 0 ? '0' : inputs.customerRate}
              disabled={!!inputs?.customer?.id}
            />
          </div>

          <div className="flex" style={{ gap: 24 }}>
            <ValidationInput
              className={inputs.contactName && globalStyles.inputTextFieldBlue}
              variant={'outlined'}
              name={'contactName'}
              label={'Contact Name*'}
              typeError={error === 'contactName' ? ErrorText.field : ''}
              onChange={handleChange}
              value={inputs.contactName}
              disabled={disables || fromGroup}
            />
            <ValidationInput
              className={inputs.customerReference && globalStyles.inputTextFieldBlue}
              variant={'outlined'}
              name={'customerReference'}
              label={'Customer Reference*'}
              typeError={error === 'customerReference' ? ErrorText.field : ''}
              onChange={handleChange}
              value={inputs.customerReference}
              disabled={disables || fromGroup}
            />
          </div>

          {backError &&
            backError !== 'Cannot use this customer due to auto deny' &&
            <ErrMessage text={backError} />
          }

          {info?.status !== CANCELLED &&
            <>
              {checkAwaiting ?
                <Button className={classes.greyButton}>
                  {info?.status === CONSIGNMENT || info?.status === REJECTED || info?.status === CONSIGNMENT_REQUEST ?
                    'Awaiting for Consignment'
                    :
                    info?.status === DENIED ?
                      'Denied'
                      :
                      'Awaiting for Credit Check'
                  }</Button>
                :
                !info?.id &&
                <CreateChancel
                  loader={loader}
                  classes={globalStyles.buttonsStyle}
                  create={tonu === true ? 'Continue' : 'Request Credit'}
                  chancel={'Cancel'}
                  onCreate={handleCreate}
                  onClose={closePrimary}
                />
              }
            </>
          }
        </div>
      </div>
    </div>
  );
};
import { userInfo, userType } from '../constants';

export const PermissionsList = {
  ALL_ACCESS: {
    title: 'Super admin',
    description: 'Super admin can do everything',
    code: 0,
  },

  READ_OFFICE: {
    title: 'Office Information',
    description: 'Viewing office Information',
    code: 1,
  },

  CREATE_UPDATE_OFFICE: {
    title: 'Create/Edit Office',
    description: 'Create and Edit offices ',
    code: 2,
  },

  OFFICE_STATUS: {
    title: 'Office Status',
    description: 'Active or deactivate offices',
    code: 3,
  },

  READ_BRANCH: {
    title: 'Branch Information',
    description: 'Viewing branch Information',
    code: 6,
  },

  CREATE_UPDATE_BRANCH: {
    title: 'Create/Edit Branch',
    description: 'Create and Edit branches',
    code: 7,
  },

  BRANCH_STATUS: {
    title: 'Branch Status',
    description: 'Active or deactivate branches',
    code: 8,
  },

  READ_ADMIN: {
    title: 'HR Information',
    description: 'Viewing HR information (no SSN)',
    code: 10,
  },

  CREATE_UPDATE_ADMIN: {
    title: 'Create/Edit HR',
    description: 'Credit and edit HR (can see SSN, can\'t add or remove HR roles)',
    code: 11,
  },

  ADMIN_STATUS: {
    title: 'HR status',
    description: 'Activate and deactivate HR',
    code: 12,
  },

  ADMIN_SSN: {
    title: 'View HR SSN',
    description: 'Only to View SSN',
    code: 13,
  },

  ADMIN_ACCESS: {
    title: 'Add/Remove HR Roles',
    description: 'Being able to add or remove HR roles for the employees (can\'t create a new role)',
    code: 14,
  },

  READ_MANAGEMENT: {
    title: 'Add/Remove roles',
    description: 'Access to create and delete roles in access management ',
    code: 15,
  },

  READ_CUSTOMER: {
    title: 'Customer Information',
    description: 'Viewing customers',
    code: 16,
  },

  CREATE_UPDATE_CUSTOMER: {
    title: 'Create/Edit Customer',
    description: 'Create and edit customers',
    code: 17,
  },

  CUSTOMER_STATUS: {
    title: 'Customer Status',
    description: 'Active or inactive customers',
    code: 18,
  },

  READ_FACTORING: {
    title: 'Factoring Information',
    description: 'Viewing factoring companies',
    code: 19,
  },

  CREATE_UPDATE_FACTORING: {
    title: 'Create/Edit Factoring',
    description: 'Create and edit factoring',
    code: 20,
  },

  FACTORING_STATUS: {
    title: 'Factoring Status',
    description: 'Active and inactive factoring',
    code: 20.1,
  },

  READ_MC: {
    title: 'Mc Information',
    description: 'Viewing mc information (office mcs) ',
    code: 21,
  },

  CREATE_UPDATE_MC: {
    title: 'Create/Edit Mc',
    description: 'Create and edit MCS (office mcs)',
    code: 22,
  },

  MC_STATUS: {
    title: 'Mc Status',
    description: 'Active and inactive Mcs (office mcs)',
    code: 23,
  },

  READ_CARRIER: {
    title: 'Carrier Information',
    description: 'Viewing carriers information',
    code: 25,
  },

  CREATE_UPDATE_CARRIER: {
    title: 'Create/Edit Carrier',
    description: 'Create and edit Carriers',
    code: 26,
  },

  CARRIER_STATUS: {
    title: 'Carrier Status',
    description: 'Active and inactive carriers',
    code: 27,
  },

  CARRIER_PAYMENT_SETUP: {
    title: 'Carrier Payment Setup',
    description: 'Setting up and editing carriers payment ',
    code: 28,
  },

  READ_CARRIER_COLLECTION: {
    title: 'Carrier Collections',
    description: 'Viewing carrier collection tab',
    code: 29,
  },

  READ_CARRIER_LOADS: {
    title: 'Carrier Loads',
    description: 'Viewing carriers loads',
    code: 24,
  },

  READ_AGENT: {
    title: 'Agent Information',
    description: 'Viewing agent profiles',
    code: 30,
  },

  CREATE_UPDATE_AGENT: {
    title: 'Create/Edit Agent',
    description: 'Create and edit agents',
    code: 31,
  },

  AGENT_STATUS: {
    title: 'Agent Status',
    description: 'Active or inactive agents',
    code: 32,
  },

  READ_PAYROLL_SETUP: {
    title: 'Bracket Information',
    description: 'Viewing brackets commissions for agents (system)',
    code: 34,
  },

  CREATE_UPDATE_PAYROLL_SETUP: {
    title: 'Create/edit Bracket information',
    description: 'Able to edit or create brackets (System)',
    code: 35,
  },

  READ_MY_CONF: {
    title: 'Confirmation Configuration Information',
    description: 'Rate con information with default or broker',
    code: 36,
  },

  CREATE_UPDATE_MY_CONF: {
    title: 'Add/Edit Confirmation Configurations',
    description: 'Add or edit rate con information for default and broker',
    code: 37,
  },

  EDIT_LOAD_DETAILS: {
    title: 'Edit Load Information',
    description: 'Editing load information (weight, mc sold as, etc)',
    code: 40,
  },

  READ_LOADS: {
    title: 'Load Tab',
    description: 'Viewing the loads',
    code: 40.1,
  },

  READ_PROCESSING: {
    title: 'Processing Tab',
    description: 'Viewing processing tab',
    code: 40.2,
  },

  READ_PAYMENT_VERIFICATION: {
    title: 'Payment Verification Tab',
    description: 'Viewing Payment Information Tab',
    code: 40.3,
  },

  READ_PAYABLE: {
    title: 'Payable Tab',
    description: 'Viewing Payables Tab',
    code: 40.4,
  },

  READ_CARRIER_PAYMENT: {
    title: 'Carrier Payment Tab',
    description: 'Viewing Carrier payment tab',
    code: 40.5,
  },

  READ_LOAD_VERIFICATION: {
    title: 'Load Verification Tab',
    description: 'Viewing Verification Loads tab',
    code: 40.6,
  },

  CANCEL_LOAD: {
    title: 'Cancel Load Button',
    description: 'For Canceling a Load',
    code: 40.7,
  },

  UPLOAD_LOAD_DOCUMENT: {
    title: 'Uploading Paperwork on loads',
    description: 'Giving access to upload paperwork ',
    code: 41,
  },

  READ_BILLING: {
    title: 'Billing Tab',
    description: 'Viewing Billing Tab',
    code: 43,
  },

  CREDIT_TAB: {
    title: 'Credit Tab',
    description: 'Viewing Credit Tab ',
    code: 44,
  },

  CREDIT_CONSIGN_TAB: {
    title: 'Consignment Tab',
    description: 'Viewing Consignment tab on credit',
    code: 44.1,
  },

  ACCEPT_DENY_CONSIGN_LOAD: {
    title: 'Accept/deny Consignment',
    description: 'Accepting and denying consignment loads',
    code: 44.2,
  },

  ACCEPT_DENY_LOAD: {
    title: 'Accept/deny Credit',
    description: 'Accepting and denying credit loads',
    code: 45,
  },

  DO_NOT_PAY: {
    title: 'Do not pay Toggle',
    description: 'Giving access to do not pay toggle',
    code: 46,
  },
  DO_NOT_BILL: {
    title: 'Do not bill Toggle',
    description: 'Giving access to do not bill toggle',
    code: 102,
  },
  TRANSACTION_VOID_BUTTON: {
    title: 'Void Load Transaction',
    description: 'Giving access to void transaction',
    code: 47,
  },

  SEND_TO_PROCESSING: {
    title: 'Send to Processing',
    description: 'Send to Processing (before payables or billing) once POD is uploaded',
    code: 48,
  },

  SEND_TO_BILLING: {
    title: 'Send to Billing',
    description: 'Button for sending to billing ',
    code: 49,
  },

  VERIFY_BILLING: {
    title: 'Verify Billing',
    description: 'Button for verifying billing ',
    code: 49.1,
  },

  SEND_TO_PAYABLE: {
    title: 'Send to Payables',
    description: 'Button for sending to payables',
    code: 53,
  },

  SEND_LOAD_TO_VERIFICATION: {
    title: 'Send to Verification',
    description: 'Button for sending to Verification',
    code: 53.1,
  },

  SEND_LOAD_TO_REVISION: {
    title: 'Send to Revision',
    description: 'Button for sending to Revision',
    code: 53.2,
  },

  REQUEST_CORRECTION_BILLING: {
    title: 'Corrections for Billing',
    description: 'Request correction for billing',
    code: 50,
  },

  SUBMIT_BILL: {
    title: 'Submit Bills',
    description: 'Finalizing billing (sending it to receivables and billing customer) ',
    code: 51,
  },

  CLOSE_BILL: {
    title: 'Close Bills',
    description: 'Close the bill if customer is not paying',
    code: 52,
  },

  ADD_TRANSACTION: {
    title: 'Add Load Transaction',
    description: 'Adding transaction to loads',
    code: 54,
  },

  CREATE_CARRIER_PAYMENT: {
    title: 'Create Payable Payment',
    description: 'Creating payments for carriers',
    code: 55,
  },

  VOID_CARRIER_PMT: {
    title: 'Void Carrier Payment',
    description: 'Allows voiding the carrier payment',
    code: 55.1,
  },

  PAYABLE_CORRECTION: {
    title: 'Correction for Payables',
    description: 'Request correction for payables',
    code: 56,
  },

  READ_RECEIVABLES: {
    title: 'Receivables Tab',
    description: 'Viewing Receivables ',
    code: 57,
  },

  VERIFY_RECEIVABLES: {
    title: 'Receivable Verification',
    description: 'Verify the receivables',
    code: 57.1,
  },

  READ_CUSTOMER_PAYMENT: {
    title: 'Customer Payment Tab',
    description: 'Viewing customer payment tab',
    code: 58,
  },

  CREATE_CUSTOMER_PAYMENT: {
    title: 'Create Customer Payment',
    description: 'Creating a customers payment ',
    code: 59,
  },

  CUSTOMER_PAYMENT_ADD_CUSTOMER_AND_LOAD: {
    title: 'Payment application',
    description: 'Applying payment to loads',
    code: 60,
  },

  VOID_CUSTOMER_PAYMENT: {
    title: 'Void Customer Payment',
    description: 'Voiding customer payments',
    code: 61,
  },

  READ_PAYROLL: {
    title: 'Payroll Tab',
    description: 'Information of payroll',
    code: 70,
  },


  // NEW LIST


  READ_QUICKBOOKS: {
    title: 'Quickbooks Tab',
    description: 'Quickbooks of payroll',
    code: 38,
  },

  ADD_QUICKBOOKS: {
    title: 'Add Quickbooks settings',
    description: 'Quickbooks settings',
    code: 39,
  },

  EXPORT_LOAD_CSV: {
    title: 'Load CSV',
    description: 'Export load csv',
    code: 80,
  },

  READ_AR_DETAILED_REPORT: {
    title: 'AR Detailed Report',
    description: 'Viewing AR Detailed Report',
    code: 80.1,
  },

  READ_AR_SUMMARY_REPORT: {
    title: 'AR Summary Report',
    description: 'Viewing AR Summary Report',
    code: 80.2,
  },

  READ_AR_ACTIVITY_REPORT: {
    title: 'AR Activity Report',
    description: 'Viewing AR Activity Report',
    code: 80.3,
  },

  READ_AR_PAYMENT_APPLICATION_REPORT: {
    title: 'Payment Application Report',
    description: 'Viewing Payment Application Report',
    code: 80.4,
  },


  READ_AP_DETAILED_REPORT: {
    title: 'AP Detailed Report',
    description: 'Viewing AP Detailed Report',
    code: 80.5,
  },

  READ_AP_SUMMARY_REPORT: {
    title: 'AP Summary Report',
    description: 'Viewing AP Summary Report',
    code: 80.6,
  },

  READ_AP_ACTIVITY_REPORT: {
    title: 'AP Activity Report',
    description: 'Viewing AP Activity Report',
    code: 80.7,
  },

  READ_AP_PAYMENT_APPLICATION_REPORT: {
    title: 'Payment Application Report',
    description: 'Viewing Payment Application Report',
    code: 80.8,
  },


  EXPORT_CARRIER_CSV: {
    title: 'Carrier CSV',
    description: 'Export carrier csv',
    code: 80.9,
  },
  EXPORT_CUSTOMER_CSV: {
    title: 'Customer CSV',
    description: 'Export customer csv',
    code: 80.11,
  },
  EXPORT_CUSTOMER_PMT_CSV: {
    title: 'Customer Pmt CSV',
    description: 'Export customer pmt csv',
    code: 80.12,
  },
  EXPORT_RECEIVABLE_CSV: {
    title: 'Receivable CSV',
    description: 'Export receivable csv',
    code: 80.13,
  },
  EXPORT_CARRIER_PMT_CSV: {
    title: 'Carrier CSV',
    description: 'Export carrier pmt csv',
    code: 80.14,
  },


  CHANGE_RMIS_SETTINGS: {
    title: 'Add RMIS settings',
    description: 'RMIS settings',
    code: 90,
  },

  SET_BOND_ON: {
    title: 'Set Bond On',
    description: 'Permission to set bond on',
    code: 100.1,
  },

  SET_BOND_OFF: {
    title: 'Set Bond Off',
    description: 'Permission to set bond off',
    code: 100.2,
  },

  SET_CLAIM_ON: {
    title: 'Set Claim On',
    description: 'Permission to set claim on',
    code: 100.3,
  },

  SET_CLAIM_OFF: {
    title: 'Set Claim Off',
    description: 'Permission to set claim off',
    code: 100.4,
  },

  READ_CLAIM_LIST: {
    title: 'View Claim List',
    description: 'Permission to read claim list',
    code: 100.5,
  },

  READ_BOND_LIST: {
    title: 'View Bond List',
    description: 'Permission to read bond list',
    code: 100.6,
  },
  MANAGE_DIRECTORY: {
    title: 'Manage directories',
    description: 'Permission to create, edit, and delete directories',
    code: 101,
  },

  MANAGE_MANUAL_PAYROLL: {
    title: 'Manage Manual Payroll',
    description: 'Access to add manual payroll',
    code: 34.1,
  },


};

export const officeManager =
  userType && userType === 'AGENT' ? userInfo?.managedOffices?.length > 0 && true : true;

export const branchManager =
  userType && userType === 'AGENT' ? userInfo?.managedBranches?.length > 0 && true : true;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { httpRequestsOnSuccessActions, loadActions } from 'store';
import {
  CustomerCredit,
  DocumentsSection,
  Processing,
} from './core';
import {
  CANCELLED,
  CONSIGNMENT_REQUEST,
  CREDITCHECK,
  REJECTED,
} from '../../../pages/accounting/constants';
import { FactoredLoadSummary } from './core/factoredLoadSummary';
import { LoadErrAndNotes } from './core/loadErrAndNotes';
import { CustomerInfo } from './core/customerInfo';
import { FactoredDestination } from './core/destination';
import { checkCancelFactoredLoad } from '../createLoad/core/constants';
import { CustomDeleteModal } from '../../modals';
import { RENDER_LOADS } from 'pages';
import { useModal } from 'utils';
import { wrapperStyle } from '../../../components/wrapper/styles';

export const CreateFactoredLoadTable = ({}) => {
  const { loadById, customers } = useSelector((state) => ({
    loadById: state.loads.loadById,
    customers: state.loads.customers,
  }));
  const classes = wrapperStyle();
  const dispatch = useDispatch();
  const params = useParams();
  const [documents, setDocuments] = useState(false);
  const [last, setLast] = useState(false);
  const [tonu, setTonu] = useState(loadById?.isTonu || false);
  const CANCEL_ACTION_TYPE = 'CANCEL_LOAD';
  const { open } = useModal();

  useEffect(() => {
    dispatch(loadActions.removeSearchedList());
  }, []);

  useEffect(() => {
    if (loadById && loadById.isTonu === true) {
      setDocuments(true);
    } else {
      if (loadById?.id && loadById?.status !== CREDITCHECK && loadById?.status !== REJECTED && loadById?.status !== CONSIGNMENT_REQUEST) {
        setDocuments(true);
      }
    }
  }, [loadById]);

  return (
    <div>
      {!loadById?.id ?
        <div className={classes.createOfficesBody}>
          <CustomerCredit
            tonu={tonu}
            info={loadById}
            customers={customers?.customers}
            handleSwitchTonu={() => setTonu(!tonu)}
          />
        </div>

        :

        <div>
          <LoadErrAndNotes loadById={loadById} />
          {loadById?.status && <FactoredLoadSummary loadById={loadById} />}
          <CustomerCredit
            tonu={tonu}
            info={loadById}
            customers={customers?.customers}
            handleSwitchTonu={() => setTonu(!tonu)}
          />
          {documents &&
            <CustomerInfo />
          }
          {documents &&
            <FactoredDestination
              info={loadById}
              id={params.id}
            />
          }
          {documents &&
            <DocumentsSection
              info={loadById}
              id={params.id}
              destination={last}
              andleChangeScreen={() => setLast(true)}
            />
          }
          {loadById?.status === 'PROCESSING' && loadById?.status !== CANCELLED &&
            <Processing info={loadById} />
          }
        </div>
      }

      {checkCancelFactoredLoad(loadById) &&
        <div className="cancel-load-btn-box">
          <button
            className="cancel-btn"
            onClick={() => open(
              <CustomDeleteModal
                afterSuccess={() => {
                  close();
                  dispatch(httpRequestsOnSuccessActions.appendSuccess(RENDER_LOADS));
                }}
                actionType={CANCEL_ACTION_TYPE}
                text="Cancel Load?"
                handleDel={() => dispatch(loadActions.cancelLoad(loadById?.id))}
                modalText={'Are you sure you want to cancel this load?'}
                cancelButton={'Cancel'}
                closeButton={'Go Back'}
              />,
            )}
          >
            Cancel Load
          </button>
        </div>
      }
    </div>
  );
};
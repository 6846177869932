import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  Offices,
  Branches,
  HumanResources,
  HumanResourcesInfoPage,
  Customers,
  Factoring,
  Authorities,
  Agents,
  Carriers,
  OfficesInfoPage,
  CustomerInfoPage,
  BranchesInfoPage,
  FactoringsInfoPage,
  CarrierInfoPage,
  AgentInfoPage,
  AuthoritiesInfoPage,
  Loads,
  System,
  HistoryPage,
  Processing,
  Billing,
  Receivables,
  CustomerPayment,
  CustomerPaymentInfo,
  Payables,
  CarrierPayment,
  CarrierPaymentInfo,
  Payroll,
  LoadVerification,
  ArReport,
  ApReport,
  Consign,
  Rejected,
  Templates,
  TemplateInfo,
  Truck,
  EquipmentDetails, OnClaim, OnBond, MyProfilePage, CreditPageWrapper, Directory, Vendors, CreateLoadGroup,
  LoadDetailSwitcher,
} from 'pages';
import {
  CreateAdminTable,
  CreateOfficeTable,
  CreateBranchTable,
  CreateAuthorities,
  CreateAgent,
  CreateFactoringTable,
  CreateCarrier,
  CreateCustomerTable,
  CreateLoadTable, GroupedLoadDetails,
} from 'fragments';
import { PaymentVerification } from '../pages/accounting';
import { ACCOUNT_ENUMS, PermissionsList, RolePermission, userType } from 'utils';

export const Router = ({}) => {

  return (
    <Switch>

      <Route path="/trucks" component={Truck} />

      {/* Office */}
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_OFFICE?.code, PermissionsList.CREATE_UPDATE_OFFICE?.code], 'officeManager') &&
        <Route exact path={['/', '/offices']} component={Offices} />}
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_OFFICE?.code, PermissionsList.CREATE_UPDATE_OFFICE?.code], 'officeManager') &&
        <Route path="/offices" exact component={Offices} />}
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_OFFICE?.code, PermissionsList.CREATE_UPDATE_OFFICE?.code], 'officeManager') &&
        <Route path="/office/:id" component={OfficesInfoPage} />}
      {RolePermission([PermissionsList?.ALL_ACCESS?.code, PermissionsList?.CREATE_UPDATE_OFFICE?.code]) &&
        <Route path="/createOffice" component={CreateOfficeTable} />}

      {/* Branch */}
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_BRANCH?.code, PermissionsList.CREATE_UPDATE_BRANCH?.code], 'officeOrBranchManager') &&
        <Route exact path={['/', '/branches']} component={Branches} />}
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_BRANCH?.code, PermissionsList.CREATE_UPDATE_BRANCH?.code], 'officeOrBranchManager') &&
        <Route path="/branch/:id" component={BranchesInfoPage} />}
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_BRANCH?.code], 'officeManager') &&
        <Route path="/createBranch" component={CreateBranchTable} />
      }

      {/* Admin */}
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_ADMIN?.code, PermissionsList.CREATE_UPDATE_ADMIN?.code]) &&
        <Route path="/humanResources" exact component={HumanResources} />}
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_ADMIN?.code, PermissionsList.CREATE_UPDATE_ADMIN?.code]) &&
        <Route path="/humanResource/:id" component={HumanResourcesInfoPage} />}
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_ADMIN?.code]) &&
        <Route path="/createHumanResources" component={CreateAdminTable} />}

      {/* Customer */}
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CUSTOMER?.code, PermissionsList.CREATE_UPDATE_CUSTOMER?.code]) &&
        <Route path="/customers" component={Customers} />
      }
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CUSTOMER?.code, PermissionsList.CREATE_UPDATE_CUSTOMER?.code]) &&
        <Route path="/customer/:id" component={CustomerInfoPage} />
      }
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CUSTOMER?.code]) &&
        <Route path="/createCustomer" component={CreateCustomerTable} />
      }

      {/* Factoring */}
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_FACTORING?.code, PermissionsList.CREATE_UPDATE_FACTORING?.code]) &&
        <Route path="/factorings" component={Factoring} />
      }
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_FACTORING?.code, PermissionsList.CREATE_UPDATE_FACTORING?.code]) &&
        <Route path="/factoring/:id" component={FactoringsInfoPage} />
      }
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_FACTORING?.code]) &&
        <Route path="/createFactoring" component={CreateFactoringTable} />
      }


      {/* Authority */}
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_MC?.code, PermissionsList.CREATE_UPDATE_MC?.code]) &&
        <Route path="/authorities" component={Authorities} />
      }
      {RolePermission([PermissionsList.READ_MC?.code, PermissionsList.CREATE_UPDATE_MC?.code]) &&
        <Route path="/authority/:id" component={AuthoritiesInfoPage} />
      }
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_MC?.code]) &&
        <Route path="/createAuthorities" component={CreateAuthorities} />
      }

      {/* Carrier */}
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CARRIER?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code], 'agentView') &&
        <Route path="/carriers" component={Carriers} />
      }
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CARRIER?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code], 'agentView') &&
        <Route path="/carrier/:id" component={CarrierInfoPage} />
      }


      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CARRIER?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code], 'agentView') &&
        <Route path="/vendors" component={Vendors} />
      }
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CARRIER?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code], 'agentView') &&
        <Route path="/vendor/:id" component={CarrierInfoPage} />
      }
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&
        <Route path="/create-vendor" component={CreateCarrier} />
      }


      {/*{userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_CARRIER?.code]) &&*/}
      {/*  <Route path="/createCarriers" component={CreateCarrier} />*/}
      {/*}*/}

      <Route path="/equipment/:id/carrier/:carrierId" component={EquipmentDetails} />

      {/* Agent */}
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_AGENT?.code, PermissionsList.CREATE_UPDATE_AGENT?.code], 'officeOrBranchManager') &&
        <Route path="/agents" component={Agents} />
      }
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_AGENT?.code, PermissionsList.CREATE_UPDATE_AGENT?.code], 'officeOrBranchManager') &&
        <Route path="/agent/:id" component={AgentInfoPage} />
      }
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREATE_UPDATE_AGENT?.code], 'officeManager') &&
        <Route path="/createAgent" component={CreateAgent} />
      }


      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_LOADS?.code], 'agentView') &&
        <Route path="/loads" component={Loads} />
      }


      {/*{RolePermission([], 'bmAgent') &&*/}
      {/*  <Route path="/create-load" component={CreateLoadTable} />*/}
      {/*}*/}
      {/*{RolePermission([], 'bmAgent') &&*/}
      {/*  <Route path="/create-load/:group" component={CreateLoadTable} />*/}
      {/*}*/}


      {RolePermission([], 'bmAgent') &&
        <Route path="/create-group" component={CreateLoadGroup} />
      }


      <Route path="/load-group/:id" component={GroupedLoadDetails} />



      <Route path="/load/:id" component={LoadDetailSwitcher} />
      <Route path="/grouped-load/:id/group/:groupId" component={LoadDetailSwitcher} />



      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([
          PermissionsList.CREATE_UPDATE_MY_CONF?.code,
          PermissionsList.CREATE_UPDATE_PAYROLL_SETUP?.code,
          PermissionsList.READ_PAYROLL_SETUP?.code,
          PermissionsList.READ_MY_CONF?.code,
          PermissionsList.READ_MANAGEMENT?.code,
        ]) &&
        <Route path="/system" component={System} />
      }

      <Route path={'/history/:id'} component={HistoryPage} />

      {RolePermission([
          PermissionsList.ALL_ACCESS?.code,
          PermissionsList.ACCEPT_DENY_LOAD?.code,
          PermissionsList.CREDIT_TAB?.code,
          PermissionsList.CREDIT_CONSIGN_TAB?.code,
          PermissionsList.ACCEPT_DENY_CONSIGN_LOAD?.code,
        ], 'agentView') &&
        <Route path="/credit" component={CreditPageWrapper} />
      }

      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREDIT_TAB?.code], 'agentView') &&
        <Route path="/rejected" component={Rejected} />
      }

      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.CREDIT_CONSIGN_TAB?.code], 'officeOrBranchManager') &&
        <Route path="/consign" component={Consign} />
      }

      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_BOND_LIST.code]) &&
        <Route path="/bonds" component={OnBond} />
      }
      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CLAIM_LIST.code]) &&
        <Route path="/claims" component={OnClaim} />
      }

      {userType !== 'ADMIN' &&
        <Route path="/templates" component={Templates} />
      }
      {userType !== 'ADMIN' &&
        <Route path="/template/:id" component={TemplateInfo} />
      }

      {userType === ACCOUNT_ENUMS?.ADMIN &&
        RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_PROCESSING?.code]) &&
        <Route path="/processing" component={Processing} />}

      {userType === ACCOUNT_ENUMS?.ADMIN &&
        RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.SUBMIT_BILL?.code, PermissionsList.READ_BILLING?.code]) &&
        <Route path="/billing" component={Billing} />
      }
      {userType === ACCOUNT_ENUMS?.ADMIN &&
        RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_RECEIVABLES?.code]) &&
        <Route path="/receivables" component={Receivables} />
      }
      {userType === ACCOUNT_ENUMS?.ADMIN &&
        RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CUSTOMER_PAYMENT?.code, PermissionsList.CREATE_CUSTOMER_PAYMENT?.code]) &&
        <Route path="/customerPayments" component={CustomerPayment} />
      }
      {userType === ACCOUNT_ENUMS?.ADMIN &&
        RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CUSTOMER_PAYMENT?.code, PermissionsList.CREATE_CUSTOMER_PAYMENT?.code]) &&
        <Route path="/customerPayment/:id" component={CustomerPaymentInfo} />
      }


      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_PAYMENT_VERIFICATION?.code]) &&
        <Route path="/paymentVerification" component={PaymentVerification} />
      }
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_LOAD_VERIFICATION?.code]) &&
        <Route path="/loadVerification" component={LoadVerification} />
      }
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_PAYABLE?.code]) &&
        <Route path="/payables" component={Payables} />
      }
      {userType === ACCOUNT_ENUMS?.ADMIN && RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_CARRIER_PAYMENT?.code]) &&
        <Route path="/carrierPayments" component={CarrierPayment} />
      }
      {userType === ACCOUNT_ENUMS?.ADMIN && <Route path="/carrierPayment/:id" component={CarrierPaymentInfo} />}

      <Route path="/directory" component={Directory} />

      {RolePermission([PermissionsList.ALL_ACCESS?.code, PermissionsList.READ_PAYROLL?.code], 'agentView') &&
        <Route path="/payroll" component={Payroll} />
      }

      {RolePermission([
        PermissionsList.READ_AR_DETAILED_REPORT?.code,
        PermissionsList.READ_AR_SUMMARY_REPORT?.code,
        PermissionsList.READ_AR_ACTIVITY_REPORT?.code,
        PermissionsList.READ_AR_PAYMENT_APPLICATION_REPORT?.code,
        PermissionsList.READ_AP_DETAILED_REPORT?.code,
        PermissionsList.READ_AP_SUMMARY_REPORT?.code,
        PermissionsList.READ_AP_ACTIVITY_REPORT?.code,
        PermissionsList.READ_AP_PAYMENT_APPLICATION_REPORT?.code,
      ]) && <>
        <Route path="/ar" component={ArReport} />
        <Route path="/ap" component={ApReport} />
      </>
      }

      {userType === ACCOUNT_ENUMS?.AGENT &&
        <Route path="/my-profile" component={MyProfilePage} />
      }

      <Redirect to={'/'} />
    </Switch>
  );
};
